import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  bigMobileScreen: boolean;
  button: string;
  desc: string;
  img: string;
  mobileScreen: boolean;
  openDialog: () => void;
  pb?: boolean;
  pt?: boolean;
  reverted?: boolean;
  title: string;
  theme: Theme;
}

export default function Section({
  bigMobileScreen, button, desc, img, mobileScreen, openDialog, pb, pt, reverted, theme, title,
}: IProps) {
  return (
    <Grid container pt={10} pb={pb ? 15 : 5} alignItems="center">
      {(reverted && !mobileScreen) ? null : (
        <Grid item md={6} xs={12} px={bigMobileScreen ? 10 : 0} pb={bigMobileScreen ? 2 : 1}>
          <img
            src={img}
            alt=""
            style={{
              maxWidth: '100%',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
            }}
          />
        </Grid>
      )}
      <Grid
        item
        md={6}
        xs={12}
        px={mobileScreen ? 1 : 8}
        pt={pt && mobileScreen ? 1 : undefined}
        textAlign="left"
      >
        <Typography
          component="h1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '32px' : '52px',
            fontWeight: 600,
            lineHeight: mobileScreen ? '36px' : '60px',
            pb: mobileScreen ? 1 : 2,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '18px' : '20px',
            lineHeight: mobileScreen ? '27px' : '30px',
            pb: mobileScreen ? 3 : 5,
          }}
        >
          {desc}
        </Typography>
        <Box textAlign="left">
          <Button variant="outlined" sx={{ textTransform: 'none', borderRadius: '35px' }} onClick={openDialog}>
            <Typography sx={{
              fontSize: mobileScreen ? '15px' : '18px',
              fontWeight: 600,
              px: mobileScreen ? 2 : 6,
              py: mobileScreen ? 0.5 : 1,
            }}
            >
              {button}
            </Typography>
          </Button>
        </Box>
      </Grid>
      {(reverted && !mobileScreen) ? (
        <Grid item md={6} xs={12} px={bigMobileScreen ? 10 : 0} pb={bigMobileScreen ? 2 : 1}>
          <img
            src={img}
            alt=""
            style={{
              maxWidth: '100%',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
