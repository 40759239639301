import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    header: {
      height: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    header?: {
      height?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ['Asap', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#1A98DB',
      light: '#F3F9FF',
    },
    secondary: {
      main: '#00456F',
      light: '#3CB4F4',
    },
    error: {
      main: '#b01f15',
    },
    background: {
      default: '#F9F9F9',
    },
    grey: {
      100: '#A4A4A4',
    },
  },
  header: {
    height: '40px',
  },
  spacing: 10,
});

export default theme;
