import {
  Box, Grid, IconButton, Dialog as MuiDialog, DialogContent, DialogContentText, DialogTitle, Typography, useMediaQuery,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import AppStores from 'src/components/AppStores';
import qrCode from 'src/assets/qrCode.svg';

interface IProps {
  body: string;
  imgDesc: string;
  mobileScreen: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}

export default function Dialog({
  body, imgDesc, mobileScreen, open, setOpen, title,
}: IProps) {
  const desktopScreen = useMediaQuery('(min-width:1300px)');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { borderRadius: '24px', maxWidth: desktopScreen ? '50%' : '80%' } }}
    >
      <Grid container p={mobileScreen ? 1 : 3}>
        {!mobileScreen && (
        <Grid item md={4} alignSelf="center" p={1} textAlign="center">
          <img
            src={qrCode}
            alt=""
            style={{
              maxWidth: '100%',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
            }}
          />
          <Typography variant="body2" sx={{ color: 'grey.100', pt: 1 }}>{imgDesc}</Typography>
        </Grid>
        )}
        <Grid item xs={12} md={8}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontSize: mobileScreen ? '24px' : '38px',
              lineHeight: mobileScreen ? '28px' : '48px',
              fontWeight: 700,
              color: 'secondary.main',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                fontSize: mobileScreen ? '15px' : '18px',
                lineHeight: mobileScreen ? '18px' : '28px',
                color: 'secondary.main',
                pb: 5,
              }}
            >
              {body}
            </DialogContentText>
            <AppStores halfSize={mobileScreen} mobileScreen={mobileScreen} sx={{ p: 0 }} />
          </DialogContent>
        </Grid>
      </Grid>
      <Box position="absolute" top="0" right="0">
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: 'secondary.main', p: 1 }} />
        </IconButton>
      </Box>
    </MuiDialog>
  );
}
