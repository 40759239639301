import { useHistory } from 'react-router-dom';
import {
  Box, Button, IconButton, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

import logo from 'src/assets/logo.svg';

interface IProps {
  button: string;
  mobileScreen: boolean;
  openDialog: () => void;
  theme: Theme;
}

export default function Header({
  button, mobileScreen, openDialog, theme,
}: IProps) {
  const history = useHistory();
  const onLogoClick = () => history.push('/');

  return (
    <Box
      component="header"
      display="flex"
      justifyContent="space-between"
      alignContent="center"
      sx={{ height: theme.header.height }}
    >
      <IconButton sx={{ borderRadius: '16px' }} onClick={onLogoClick}>
        <img src={logo} alt="GoWish Logo" />
      </IconButton>
      {mobileScreen ? null : (
        <Button
          onClick={openDialog}
          variant="contained"
          sx={{
            boxShadow: '0px 4px 22px 5px rgb(0 0 0 / 14%)',
            textTransform: 'none',
            borderRadius: '20px',
            '&:hover': { boxShadow: '0px 4px 22px 5px rgb(0 0 0 / 14%)' },
          }}
        >
          <Typography sx={{
            color: '#ffffff', fontSize: '18px', fontWeight: 600, px: 3,
          }}
          >
            {button}
          </Typography>
        </Button>
      )}
    </Box>
  );
}
