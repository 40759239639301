import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { dk, en } from 'src/texts';

i18n
  .use(initReactI18next)
  .init({
    ns: Object.keys(en),
    resources: {
      dk: {
        main: dk,
      },
      en: {
        main: en,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
