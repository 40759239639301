import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import logo from 'src/assets/logo.svg';
import cloudsBg from 'src/assets/cloudsBg.svg';
import AppStores from 'src/components/AppStores';

interface IProps {
  mobileScreen: boolean;
}

export default function Footer({ mobileScreen }: IProps) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language || 'en');
  const largeScreen = useMediaQuery('(min-width:1450px)');
  const smallScreen = useMediaQuery('(max-width:1400px)');
  const break3 = smallScreen ? '40.964%' : '74.48%';
  const break4 = smallScreen ? '55%' : '100%';
  const additional = smallScreen ? ', #fff 56%' : '';

  // TODO simplify
  const lgCss = `linear-gradient(to bottom, #fff, #eef9ff 29.17%, #8dcdef ${break3}, #1a98db ${break4 + additional})`;

  return (
    <Box
      component="footer"
      mx={mobileScreen ? 0 : -3}
      pt={mobileScreen ? 5 : 25}
      sx={
        {
          background: !mobileScreen ? `url(${cloudsBg}), ${lgCss}` : 'none',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          boxShadow: largeScreen ? 'inset -50px 0px 10px -13px rgba(255,255,255,1)' : 'none',
        }
      }
    >
      <Grid
        container
        xs={12}
        alignItems="center"
        p={mobileScreen ? 0 : 3}
        textAlign="left"
        sx={{ backgroundColor: 'white' }}
      >
        <Grid item xs={9} md={6} p={mobileScreen ? 1 : 0}>
          <img src={logo} alt="GoWish Logo" />
        </Grid>
        {mobileScreen && (
        <Grid item xs={3} md={6}>
          <Box sx={{
            maxWidth: '80px', textAlign: 'left', marginLeft: 'auto',
          }}
          >
            <FormControl fullWidth>
              <Select
                labelId="select-lang-label"
                id="select-lang"
                value={lang}
                onChange={(event) => {
                  setLang(event.target.value);
                  i18n.changeLanguage(event.target.value);
                }}
                sx={{ '& .MuiInputBase-input': { padding: 1 } }}
              >
                <MenuItem value="en">EN</MenuItem>
                <MenuItem value="dk">DK</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        )}
        <Grid item xs={12} md={6}>
          <AppStores
            mobileScreen={mobileScreen}
            sx={{
              py: 3,
              justifySelf: 'flex-end',
              justifyContent: mobileScreen ? 'center' : 'flex-end',
              pr: !mobileScreen ? 5 : 0,
            }}
          />
        </Grid>
      </Grid>
      <Grid container px={mobileScreen ? 0 : 3} sx={{ backgroundColor: 'white' }}>
        {!mobileScreen && (
          <Grid item xs={3} md={6}>
            <Box sx={{
              maxWidth: '80px', textAlign: 'left',
            }}
            >
              <FormControl fullWidth>
                <Select
                  labelId="select-lang-label"
                  id="select-lang"
                  value={lang}
                  onChange={(event) => {
                    setLang(event.target.value);
                    i18n.changeLanguage(event.target.value);
                  }}
                  sx={{ '& .MuiInputBase-input': { padding: 1 } }}
                >
                  <MenuItem value="en">EN</MenuItem>
                  <MenuItem value="dk">DK</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          borderTop="1px solid #E1E1E1"
          px={mobileScreen ? 0 : 3}
          py={3}
        >
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexDirection={mobileScreen ? 'column' : 'row'}
            textAlign={mobileScreen ? 'center' : 'left'}
          >
            <Link to="/docs/cookie" style={{ textDecoration: 'none' }}>
              <Typography
                variant="body2"
                sx={{ color: '#A4A4A4', pb: mobileScreen ? 1 : 0 }}
              >
                {t('main:footer:cookie')}
              </Typography>
            </Link>
            <Link to="/docs/tandc" style={{ textDecoration: 'none' }}>
              <Typography
                variant="body2"
                sx={{ color: '#A4A4A4', pb: mobileScreen ? 1 : 0 }}
              >
                {t('main:footer:tandc')}
              </Typography>
            </Link>
            <Link to="/docs/privacy" style={{ textDecoration: 'none' }}>
              <Typography variant="body2" sx={{ color: '#A4A4A4' }}>{t('main:footer:privacy')}</Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
