/* eslint-disable max-len */
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  Doc,
  Footer,
  Header,
  ScrollToTop,
} from 'src/components';

const cookieSections = [
  {
    subtitle: 'Cookies',
    body: 'Nedenfor kan du læse mere om, hvad cookies er, og hvordan Ønskeskyen ApS (”vi, ”os” eller ”vores”) benytter cookies på dette websted (www.ønskeskyen.dk). Vi opfordrer også til, at du læser vores Privatlivspolitik, hvor vi har beskrevet, hvordan Ønskeskyen ApS behandler dine personoplysninger. Vores Privatlivspolitik er tilgængelig på vores websted her.',
  },
  {
    subtitle: 'Hvad er cookies?',
    body: 'Nedenfor kan du læse mere om, hvad cookies er, og hvordan Ønskeskyen ApS (”vi, ”os” eller ”vores”) benytter cookies på dette websted (www.ønskeskyen.dk). Vi opfordrer også til, at du læser vores Privatlivspolitik, hvor vi har beskrevet, hvordan Ønskeskyen ApS behandler dine personoplysninger. Vores Privatlivspolitik er tilgængelig på vores websted her.',
  },
  {
    subtitle: 'Samtykke til cookies',
    body: 'Nedenfor kan du læse mere om, hvad cookies er, og hvordan Ønskeskyen ApS (”vi, ”os” eller ”vores”) benytter cookies på dette websted (www.ønskeskyen.dk). Vi opfordrer også til, at du læser vores Privatlivspolitik, hvor vi har beskrevet, hvordan Ønskeskyen ApS behandler dine personoplysninger. Vores Privatlivspolitik er tilgængelig på vores websted her.',
  },
  {
    subtitle: 'Brug af cookies til statistik, brugerpræferencer og målrettet markedsføring fra os og vore annoncører til cookies',
    body: 'Nedenfor kan du læse mere om, hvad cookies er, og hvordan Ønskeskyen ApS (”vi, ”os” eller ”vores”) benytter cookies på dette websted (www.ønskeskyen.dk). Vi opfordrer også til, at du læser vores Privatlivspolitik, hvor vi har beskrevet, hvordan Ønskeskyen ApS behandler dine personoplysninger. Vores Privatlivspolitik er tilgængelig på vores websted her.',
  },
  {
    subtitle: 'Sletning af cookies',
    body: 'Nedenfor kan du læse mere om, hvad cookies er, og hvordan Ønskeskyen ApS (”vi, ”os” eller ”vores”) benytter cookies på dette websted (www.ønskeskyen.dk). Vi opfordrer også til, at du læser vores Privatlivspolitik, hvor vi har beskrevet, hvordan Ønskeskyen ApS behandler dine personoplysninger. Vores Privatlivspolitik er tilgængelig på vores websted her.',
  },
];

export default function DocsContainer() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const openDialog = () => setDialogOpen(true);

  return (
    <Box p={3} pb={0} maxWidth="1400px" margin="auto" sx={{ overflowX: 'hidden' }}>
      <ScrollToTop />
      <Header
        button={t('main:header:button')}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        theme={theme}
      />
      <Switch>
        <Route path="/docs/cookie">
          <Doc mobileScreen={mobileScreen} sections={cookieSections} title="Cookie Policy" />
        </Route>
        <Route path="/docs/tandc">
          <Doc mobileScreen={mobileScreen} sections={cookieSections} title="Terms and Conditions" />
        </Route>
        <Route path="/docs/privacy">
          <Doc mobileScreen={mobileScreen} sections={cookieSections} title="Privacy Policy" />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer mobileScreen={mobileScreen} />
      <Dialog
        body={t('main:dialog:body')}
        imgDesc={t('main:dialog:imgDesc')}
        mobileScreen={mobileScreen}
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={t('main:dialog:title')}
      />
    </Box>
  );
}
