import {
  Box, Button, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

import iphone from 'src/assets/lead.png';
import cloudFront from 'src/assets/cloudFront.svg';
import cloudBack from 'src/assets/cloudBack.svg';
import smallCloud from 'src/assets/smallCloud.svg';
import AppStores from 'src/components/AppStores';

interface IProps {
  button: string;
  body1: string;
  body2: string;
  mobileScreen: boolean;
  openDialog: () => void;
  theme: Theme;
  title: string;
}

export default function Section({
  button, body1, body2, mobileScreen, openDialog, theme, title,
}: IProps) {
  return (
    <Box
      mt={mobileScreen ? 3 : 8}
      pl={mobileScreen ? 0 : 10}
      display="grid"
      gridTemplateColumns={mobileScreen ? '1fr' : '1fr 1fr'}
      sx={{ height: `calc(100vh - ${theme.header.height} - ${theme.spacing(8)})`, minHeight: '420px' }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          component="h1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '36px' : '64px',
            fontWeight: 600,
            lineHeight: mobileScreen ? '36px' : '64px',
            pb: 3,
            animation: 'fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '18px' : '20px',
            pb: 2,
            animation: 'fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
            animationDelay: '1s',
          }}
        >
          {body1}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '18px' : '20px',
            pb: mobileScreen ? 2.5 : 5,
            animation: 'fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
            animationDelay: '1.5s',
          }}
        >
          {body2}
        </Typography>
        <Box pb={mobileScreen ? 2.5 : 5}>
          <Button
            onClick={openDialog}
            variant="contained"
            sx={{
              boxShadow: '0px 4px 22px 5px rgb(0 0 0 / 14%)',
              textTransform: 'none',
              borderRadius: '35px',
              '&:hover': { boxShadow: '0px 4px 22px 5px rgb(0 0 0 / 14%)' },
            }}
          >
            <Typography sx={{
              color: '#ffffff',
              fontSize: mobileScreen ? '15px' : '18px',
              fontWeight: 600,
              px: mobileScreen ? 3.5 : 7,
              py: 1,
            }}
            >
              {button}
            </Typography>
          </Button>
        </Box>
        <AppStores mobileScreen={mobileScreen} />
      </Box>
      {mobileScreen ? null : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          sx={{ maxHeight: `calc(100vh - ${theme.header.height} - ${theme.spacing(10)})` }}
        >
          <img
            src={iphone}
            alt="GoWish iPhone"
            style={{
              maxWidth: '100vw',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
            }}
          />
          <img
            src={cloudFront}
            alt=""
            style={{
              maxWidth: '90%',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
              position: 'absolute',
              top: '10%',
              left: 0,
              zIndex: -1,
              animation: 'float 40s ease-in-out infinite',
            }}
          />
          <img
            src={cloudBack}
            alt=""
            style={{
              maxWidth: '90%',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
              position: 'absolute',
              top: '5%',
              left: '10%',
              zIndex: -2,
              animation: 'float2 56s ease-in-out infinite',
            }}
          />
          <img
            src={smallCloud}
            alt=""
            style={{
              maxWidth: '200px',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
              position: 'absolute',
              top: '-60px',
              left: 0,
              zIndex: -3,
              animation: 'float3 32s ease-in-out infinite',
            }}
          />
          <img
            src={smallCloud}
            alt=""
            style={{
              maxWidth: '200px',
              height: 'auto',
              width: 'auto',
              maxHeight: 'inherit',
              position: 'absolute',
              top: '60px',
              right: 0,
              zIndex: -4,
              animation: 'float4 48s ease-in-out infinite',
            }}
          />
        </Box>
      )}
    </Box>
  );
}
