import { Box } from '@mui/material';

import appleStore from 'src/assets/appleStore.svg';
import googlePlay from 'src/assets/googlePlay.svg';
import Css from 'src/utils/Css';

interface IProps {
  halfSize?: boolean;
  mobileScreen: boolean;
  sx?: Css;
}

export default function AppStores({
  halfSize, mobileScreen, sx,
}: IProps) {
  return (
    <Box display="flex" py={3} sx={sx || undefined}>
      <img
        src={appleStore}
        alt="GoWish on App Store"
        style={{
          maxWidth: halfSize ? 'calc(50% - 5px)' : '100%',
          height: 'auto',
          width: 'auto',
          maxHeight: 'inherit',
        }}
      />
      <img
        src={googlePlay}
        alt="GoWish on Google Play"
        style={{
          maxWidth: halfSize ? 'calc(50% - 5px)' : '100%',
          height: 'auto',
          width: 'auto',
          maxHeight: 'inherit',
          paddingLeft: mobileScreen || halfSize ? '10px' : '30px',
        }}
      />
    </Box>
  );
}
