/* eslint-disable max-len */

const dk = {
  header: {
    button: 'Download i dag',
  },
  leadSection: {
    button: 'Download i dag',
    body1: 'Udfyld din ønskeliste med produkter fra dine foretrukne webshops, og del den med dine venner og familie.',
    body2: 'Dine ønsker er tættere på, end du tror.',
    title: 'Gem og\ndel dine ønsker',
  },
  fullWidthSection: {
    button: 'Download i dag',
    body1: 'GoWish er Danmarks mest anvendte platform til oprettelse af ønskelister.',
    body2: 'Vores mål er at gøre det ubesværet at gemme og dele ønsker over hele verden.',
    title: 'Mere end 1,2 millioner ønskelister',
  },
  section1: {
    button: 'Hent appen',
    desc: 'Tryk på ønskeskyen eller plus, og begynd at gemme ønsker på dine ønskelister.',
    title: 'Gem dine ønsker med et klik',
  },
  section2: {
    button: 'Start med at dele dine ønsker',
    desc: 'Del direkte fra appen, så det er let for dem at finde den perfekte gave til dig.',
    title: 'Del dine ønsker med venner og familie',
  },
  section3: {
    button: 'Download i dag',
    desc: 'Med GoWish har din familie og venner mulighed for at reservere ønsker på din ønskeliste.',
    title: 'Undgå at få den samme gave to gange',
  },
  footer: {
    cookie: 'Cookiepolitik',
    tandc: 'Vilkår og betingelser',
    privacy: 'Fortrolighedspolitik',
  },
  dialog: {
    body: 'Download Gowish appen, og hav altid dine ønsker i lommen.',
    imgDesc: 'Scan for at gå til store',
    title: 'Dine ønsker er tættere på, end du tror',
  },
};

export default dk;
