import {
  Box, Button, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  button: string;
  body1: string;
  body2: string;
  mobileScreen: boolean;
  openDialog: () => void;
  theme: Theme;
  title: string;
}

export default function FullWidthSection({
  button, body1, body2, mobileScreen, openDialog, theme, title,
}: IProps) {
  return (
    <Box
      pt={3}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Typography
          component="h1"
          sx={{
            color: theme.palette.secondary.main,
            fontSize: mobileScreen ? '32px' : '52px',
            fontWeight: 600,
            lineHeight: mobileScreen ? '36px' : '60px',
            pb: 3,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.secondary.main, fontSize: mobileScreen ? '18px' : '20px', pb: 2 }}
        >
          {body1}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.secondary.main, fontSize: mobileScreen ? '18px' : '20px', pb: 5 }}
        >
          {body2}
        </Typography>
        <Box pb={5}>
          <Button variant="contained" sx={{ textTransform: 'none', borderRadius: '35px' }} onClick={openDialog}>
            <Typography sx={{
              color: '#ffffff',
              fontSize: mobileScreen ? '15px' : '18px',
              fontWeight: 600,
              px: mobileScreen ? 3.5 : 7,
              py: 1,
            }}
            >
              {button}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
