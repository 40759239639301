import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import Routes from './Routes';
import theme from './styles/theme';
import i18n from './utils/i18n';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <Routes />
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  );
}
