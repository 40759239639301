import { Route, Redirect, Switch } from 'react-router-dom';

import { LandingPage, DocsContainer } from 'src/containers';

export default function Routes() {
  return (
    <Switch>
      <Route path="/docs" component={DocsContainer} />
      <Route path="/" component={LandingPage} />
      <Redirect to="/" />
    </Switch>
  );
}
