import { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  Footer,
  FullWidthSection,
  Header,
  LeadSection,
  Section,
} from 'src/components';
import cloud1 from 'src/assets/cloud1.png';
import cloud2 from 'src/assets/cloud2.png';
import cloud3 from 'src/assets/cloud3.png';

export default function LandingPange() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const bigMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const openDialog = () => setDialogOpen(true);

  return (
    <Box p={3} pb={0} minWidth="300px" maxWidth="1400px" margin="auto" sx={{ overflowX: 'hidden' }}>
      <Header
        button={t('main:header:button')}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        theme={theme}
      />
      <LeadSection
        body1={t('main:leadSection:body1')}
        body2={t('main:leadSection:body2')}
        button={t('main:leadSection:button')}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        theme={theme}
        title={t('main:leadSection:title')}
      />
      <FullWidthSection
        button={t('main:fullWidthSection:button')}
        body1={t('main:fullWidthSection:body1')}
        body2={t('main:fullWidthSection:body2')}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        theme={theme}
        title={t('main:fullWidthSection:title')}
      />
      <Section
        bigMobileScreen={mobileScreen && bigMobileScreen}
        button={t('main:section1:button')}
        desc={t('main:section1:desc')}
        img={cloud1}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        theme={theme}
        title={t('main:section1:title')}
      />
      <Section
        bigMobileScreen={mobileScreen && bigMobileScreen}
        button={t('main:section2:button')}
        desc={t('main:section2:desc')}
        img={cloud2}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        pt
        reverted
        theme={theme}
        title={t('main:section2:title')}
      />
      <Section
        bigMobileScreen={mobileScreen && bigMobileScreen}
        button={t('main:section3:button')}
        desc={t('main:section3:desc')}
        img={cloud3}
        mobileScreen={mobileScreen}
        openDialog={openDialog}
        pb
        pt
        theme={theme}
        title={t('main:section3:title')}
      />
      <Footer mobileScreen={mobileScreen} />
      <Dialog
        body={t('main:dialog:body')}
        imgDesc={t('main:dialog:imgDesc')}
        mobileScreen={mobileScreen}
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={t('main:dialog:title')}
      />
    </Box>
  );
}
