/* eslint-disable max-len */

const en = {
  header: {
    button: 'Download GoWish',
  },
  leadSection: {
    button: 'Download Today',
    body1: 'Fill up your wishlist with products from your favorite webshops, and share it with your friends and family.',
    body2: 'Your wishes are closer than you think.',
    title: 'Save and\nshare your wishes',
  },
  fullWidthSection: {
    button: 'Download Today',
    body1: 'GoWish is Denmarks most used platform for creating wish lists.',
    body2: 'Our goal is to make it effortless to save and share wishes all over the world.',
    title: 'More than 1.2 million wish lists',
  },
  section1: {
    button: 'Get the app',
    desc: 'Press the wish cloud or the plus and start saving wishes to your wish lists.',
    title: 'Save your wishes in one click',
  },
  section2: {
    button: 'Start sharing your wishes',
    desc: 'Share directly from the app to make it easy for them to find the perfect gift for you.',
    title: 'Share your wishes with friends and family',
  },
  section3: {
    button: 'Download today',
    desc: 'With GoWish your family and friends have the posibility to reserve wishes on your wish list.',
    title: 'Avoid getting the same gift twice',
  },
  footer: {
    cookie: 'Cookie Policy',
    tandc: 'Terms and Conditions',
    privacy: 'Privacy Policy',
  },
  dialog: {
    body: 'Download the Gowish app and have your wishes in the pocket at all times.',
    imgDesc: 'Scan to go to store',
    title: 'Your wishes are closer than you think',
  },
};

export default en;
