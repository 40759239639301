import {
  Box, Typography,
} from '@mui/material';

interface IProps {
  mobileScreen: boolean;
  sections: { subtitle: string; body: string }[];
  title: string;
}

export default function Doc({ mobileScreen, sections, title }: IProps) {
  return (
    <Box pt={mobileScreen ? 3 : 15} p={mobileScreen ? 1.5 : 15} color="secondary.main">
      <Typography
        component="h1"
        sx={{ fontWeight: 600, fontSize: mobileScreen ? '32px' : '36px', pb: mobileScreen ? 2.5 : 5 }}
      >
        {title}
      </Typography>
      {sections.map(({ body, subtitle }) => (
        <Box key={title} pb={5}>
          <Typography
            component="h2"
            gutterBottom
            sx={{ fontWeight: 600, fontSize: mobileScreen ? '22px' : '32px' }}
          >
            {subtitle}

          </Typography>
          <Typography sx={{ fontSize: mobileScreen ? '16px' : '20px' }}>{body}</Typography>
        </Box>
      ))}
    </Box>
  );
}
